import React from "react";
import "./App.css";
import profilePic from "./avatar.jpeg"; // Place your profile picture in the src folder

function App() {
  return (
    <div className="App">
      <div className="container">
        <header>
          <img src={profilePic} alt="Amir Kiumarsi" className="profile-pic" />
          <h1>Amir Kiumarsi</h1>
          <h2>Freelance Software Consultant and Developer</h2>
          <h3>Based in The Hague</h3>
        </header>
        <main>
          <p>
            I specialize in e-commerce solutions and have extensive experience
            with JavaScript, TypeScript, Node.js, React.js, Angular, and Vue.js.
            I'm also proficient in Terraform, Azure, and CI/CD pipelines.
          </p>
          <p>
            I'm interested in AI and machine learning and am always eager to
            integrate cutting-edge technologies into my projects.
          </p>
          <div className="links">
            <a href="mailto:amir-rt@live.com">Email</a>
            <a
              href="https://www.linkedin.com/in/amirkiumarsi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
            <a
              href="https://github.com/codacy20"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </a>
            <a href="/res.pdf" target="_blank" rel="noopener noreferrer">
              Resume
            </a>
          </div>
          <div className="business-info">
            <p>
              <strong>KvK Number:</strong> 75303248
            </p>
            <p>
              <strong>BTW (VAT) Number:</strong> NL002502832B22
            </p>
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
